
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AppService, { renderImageUrl } from "@/core/services/AppService";
import { AdminDefault, phone, AdminModel } from "@/store/model";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Actions } from "@/store/enums/StoreEnums";
import axios from "axios";

export default defineComponent({
  name: "account-settings",
  setup() {
    const image_editor = ref();
    const rules = ref({
      name: [
        {
          required: true,
          message: "Họ tên không được để trống",
          trigger: "blur",
        },
      ],
    });
    const profileData = ref<AdminModel>(AdminDefault);
    onMounted(() => {
      profileData.value = store.getters.currentUser;
      setCurrentPageBreadcrumbs("Thông tin tài khoản", ["Account"]);
    });
    const updateInformation = () => {
      store
        .dispatch(Actions.UPDATE_PROFILE, profileData.value)
        .then(() => {
          Swal.fire({
            text: "Cập nhật thông tin thành công",
            icon: "success",
            buttonsStyling: false,
            showCancelButton: true,
            timer: 1000,
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
              cancelButton: "btn fw-bold btn-light-primary",
            },
          }).then(function (result) {
            // Go to page after successfully login
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Thử lại!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };
    const uploadImageSingle = (event) => {
      const formData = new FormData();
      for (let x = 0; x < event.target.files.length; x++) {
        formData.append("upload_files", event.target.files[x]);
      }
      axios
        .post(process.env.VUE_APP_MEDIA_URL + "api/v1/upload/files", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(async ({ data }) => {
          profileData.value.avatar = data.data[0];
        })
        .catch((err) => {
          console.error(err);
        });
    };
    return {
      AppService,
      renderImageUrl,
      uploadImageSingle,
      updateInformation,
      profileData,
      image_editor,
      rules,
    };
  },
  mounted() {
    //Event Listener for Iframe
    window.addEventListener("message", this.iframeEvent, false);
  },
  methods: {
    iframeEvent(event) {
      if (event.data.action == "editor") {
        this.image_editor = event.data.image;
      }
    },
  },
});
